<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.agencyCenter") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <img v-if="locale==='en'" src="@/Content/images/team.jpg" style="width:100%">
      <img v-if="locale==='pb'" src="@/Content/images/team_pb.png" style="width:100%">
      <img v-if="locale==='ind'" src="@/Content/images/team_ind.jpg" style="width:100%">
      <img v-if="locale==='tur'" src="@/Content/images/team_tur.jpg" style="width:100%">
      <div class="Sys_list_div pl-20">
        <div class="box_div">
          <a @click="go('subordinatesManagement')">
            <span class="name">{{
              t("profile.agencyCenter.subordinatesManagement")
            }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div>
        <!-- <div class="box_div">
          <a @click="go('subordinatesProfit')">
            <span class="name">{{ t("common.title.subordinatesProfit") }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div>
        <div class="box_div">
          <a @click="go('TeamRO')">
            <span class="name">{{ t("profile.agencyCenter.TeamRO") }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div>
        <div class="box_div">
          <a @click="go('orderROT')">
            <span class="name">{{ t("profile.agencyCenter.orderROT") }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div>
        <div class="box_div">
          <a @click="go('accountROT')">
            <span class="name">{{ t("profile.agencyCenter.accountROT") }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div> -->
        <div class="box_div">
          <a @click="go('withdrawROT')">
            <span class="name">{{
              t("profile.agencyCenter.withdrawROT")
            }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div>
        <div class="box_div">
          <a @click="go('rechargeROT')">
            <span class="name">{{
              t("profile.agencyCenter.rechargeROT")
            }}</span>
            <span class="iconfont icon-you2"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import { agency } from "@/hooks/agency";
import { useRouter } from "vue-router";
export default {
  setup() {
    const { t,locale } = useI18n();
    const router = useRouter();
    const go = (value) => {
      switch (value) {
        case "subordinatesManagement":
          router.push({ path: "/subordinatesManagement" });
          break;
        case "subordinatesProfit":
          router.push({ path: "/subordinatesProfit" });
          break;
        case "TeamRO":
          agency.value = {
            userAccount: "",
            current: "teamReports",
            teamAccountRecordsType: "transactionRecord",
          };
          router.push({ path: "/agencyRecords" });
          break;
        case "orderROT":
          agency.value = {
            userAccount: "",
            current: "teamOrderRecords",
            teamAccountRecordsType: "transactionRecord",
          };
          router.push({ path: "/agencyRecords" });
          break;
        case "accountROT":
          agency.value = {
            userAccount: "",
            current: "teamAccountRecords",
            teamAccountRecordsType: "transactionRecord",
          };
          router.push({ path: "/agencyRecords" });
          break;
        case "withdrawROT":
          agency.value = {
            userAccount: "",
            current: "teamAccountRecords",
            teamAccountRecordsType: "withdrawalRecords",
          };
          router.push({ path: "/agencyRecords" });
          break;
        case "rechargeROT":
          agency.value = {
            userAccount: "",
            current: "teamAccountRecords",
            teamAccountRecordsType: "rechargeRrecord",
          };
          router.push({ path: "/agencyRecords" });
          break;

        default:
          break;
      }
    };
    return {
      t,
      locale,
      go,
    };
  },
};
</script>